import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService, JwtInterceptor } from '@auth0/angular-jwt';

import { AuthService } from '../services/auth.service';

import { NGXLogger } from 'ngx-logger';

import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

@Injectable()
export class RenewTokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private jwtInterceptor: JwtInterceptor,
              private jwtHelper: JwtHelperService, private logger: NGXLogger) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.jwtInterceptor.isAllowedDomain(req) && !this.jwtInterceptor.isDisallowedRoute(req)) {
      return next.handle(req).pipe(
        catchError((err) => {
          const errorResponse = err as HttpErrorResponse;
          if (errorResponse.status === 403 && errorResponse.error && errorResponse.error.message === 'Access Denied' &&
            localStorage.getItem('jwt_token') !== null && this.jwtHelper.isTokenExpired()) {
            return this.authService.renewToken().pipe(mergeMap((authData: any) => {
              this.logger.debug('[Auth API] renewToken:: JWT Token renewed.');
              localStorage.setItem('jwt_token', authData.jwtToken);
              localStorage.setItem('renew_token_id', authData.renewTokenId);
              return this.jwtInterceptor.intercept(req, next);
            }));
          }
          return throwError(err);
        }));
    } else {
      return next.handle(req);
    }
  }
}
