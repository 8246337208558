import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { stringify } from 'querystring';

import { environment } from '../../../environments/environment';
// import { ExtProfile } from '@models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private static readonly LOGIN_WITH_EMAIL_API_PATH = `${environment.apiUrl}/authentication/login/username-password`;
  private static readonly REGISTER_WITH_EMAIL_API_PATH = `${environment.apiUrl}/user/public/register`;
  private static readonly ACTIVATE_EMAIL_API_PATH = `${environment.apiUrl}/authentication/email-activate`;
  // private static readonly UPDATE_USER_API_PATH = `${environment.apiUrl}/user/update`;
  private static readonly RECOVER_PASSWORD_EMAIL_API_PATH = `${environment.apiUrl}/user/public/forgot-password/email`;
  private static readonly EMAIL_RESET_EMAIL_API_PATH = `${environment.apiUrl}/user/update-email/email/`;
  private static readonly RESET_PASSWORD_API_PATH = `${environment.apiUrl}/authentication/forgot-password/reset`;
  private static readonly LOGIN_WITH_FACEBOOK_API_PATH = `${environment.apiUrl}/authentication/login/facebook/code`;
  private static readonly LOGOUT_API_PATH = `${environment.apiUrl}/authentication/logout`;
  private static readonly RENEW_TOKEN_API_PATH = `${environment.apiUrl}/authentication/renew-token`;
  private static readonly GET_USER_API_PATH = `${environment.apiUrl}/user/me`;
  private static readonly GET_USER_BALANCE_API_PATH = `${environment.apiUrl}/user/me/balance`;
  private static readonly GET_ONLINE_USERS_API_PATH = `${environment.apiUrl}/user/public/number-online`;
  private static readonly UPDATE_SELF_EXCLUSION_API_PATH = `${environment.apiUrl}/exclusion/create`;
  private static readonly ACTIVATE_FAUCED_API_PATH = `${environment.apiUrl}/user/activate-fauced`;
  private static readonly RESET_EMAIL_ADDRESS_API_PATH = `${environment.apiUrl}/user/update-email/reset/`;

  static initializeFacebookLogin() {
    const params = {
      client_id: '746117839199161',
      response_type: 'token',
      redirect_uri: `${environment.baseUrl}/auth/facebook/verify`,
      scope: 'email',
      state: '{redirect=true}'
    };

    window.location.href = `https://www.facebook.com/v7.0/dialog/oauth?${stringify(params)}`;
  }

  constructor(private http: HttpClient) { }

  loginWithEmail(email: string, password: string) {
    return this.http.post(AuthService.LOGIN_WITH_EMAIL_API_PATH, {username: email, password});
  }

  registerWithEmail(email: string, password: string) {
    const referralCodeUsed = localStorage.getItem('referral.code');
    if (referralCodeUsed) {
      return this.http.post(AuthService.REGISTER_WITH_EMAIL_API_PATH, {email, password, referralCodeUsed});
    } else {
      return this.http.post(AuthService.REGISTER_WITH_EMAIL_API_PATH, {email, password});
    }
  }

  activateEmail(token: string) {
    return this.http.post(`${AuthService.ACTIVATE_EMAIL_API_PATH}`, {token});
  }

  /*updateUser(extProfile: ExtProfile, email: string, tradeLinkUrl: string, twoFaSecret: string) {
    return this.http.put(`${AuthService.UPDATE_USER_API_PATH}`, {extProfile, email, tradeLinkUrl, twoFaSecret});
  }*/

  recoveryPasswordEmail(email: string) {
    return this.http.post(`${AuthService.RECOVER_PASSWORD_EMAIL_API_PATH}`, {email});
  }

  emailResetEmail(email: string) {
    return this.http.post(`${AuthService.EMAIL_RESET_EMAIL_API_PATH}`, {email});
  }

  resetPassword(token: string, password: string) {
    return this.http.post(`${AuthService.RESET_PASSWORD_API_PATH}`, {token, password});
  }

  loginWithFacebook(code: string) {
    const referralCode = localStorage.getItem('referral.code');
    if (referralCode) {
      const headers = {'referral.code': referralCode};
      return this.http.post(`${AuthService.LOGIN_WITH_FACEBOOK_API_PATH}/` + code, null, { headers });
    } else {
      return this.http.post(`${AuthService.LOGIN_WITH_FACEBOOK_API_PATH}/` + code, null);
    }
  }

  renewToken() {
    const token = localStorage.getItem('renew_token_id');
    return this.http.post(`${AuthService.RENEW_TOKEN_API_PATH}`, {token});
  }

  logout() {
    return this.http.post(`${AuthService.LOGOUT_API_PATH}`, null);
  }

  getUser() {
    return this.http.get(`${AuthService.GET_USER_API_PATH}`);
  }

  getUserBalance() {
    return this.http.get(`${AuthService.GET_USER_BALANCE_API_PATH}`);
  }

  getOnlineUsers() {
    return this.http.get(`${AuthService.GET_ONLINE_USERS_API_PATH}`);
  }

  updateSelfExclusion(exclusionType: string, period: string) {
    return this.http.post(`${AuthService.UPDATE_SELF_EXCLUSION_API_PATH}`, {exclusionType, period});
  }

  activateFauced() {
    return this.http.post(`${AuthService.ACTIVATE_FAUCED_API_PATH}`, {});
  }

  updateEmailAddress(token: string) {
    return this.http.put(`${AuthService.RESET_EMAIL_ADDRESS_API_PATH}` + token, {});
  }
}
